import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'gatsby-plugin-fresnel';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './LargePost.module.scss';
import { datePostedGmtToString } from '../../utilities';

/**
 * Component to display Large Post (Article)
 */
const LargePost = (props) => {
  const {
    imgData,
    imgDataTabletOnly,
    imgAlt,
    imgHoverTitle,
    category,
    categoryLink,
    title,
    lead,
    authorImgData,
    authorName,
    authorSlug,
    datePostedGmt,
    timeToRead,
    slug,
    tag,
    sustainability = false,
    ...rest
  } = props;

  const { t } = useTranslation();

  return (
    <div
      {...rest}
      className={`${style.largePost} ${
        sustainability ? style.sustainability : ''
      }`}
    >
      {imgData && imgData?.images && (
        <Link className={`clearLinkStyle d-block ${style.imageLink}`} to={slug}>
          {!sustainability && tag && <div className={style.tag}>{tag}</div>}
          <Media lessThan="tablet">
            {(mediaClassNames) => (
              <GatsbyImage
                alt={imgAlt}
                image={imgData}
                className={`${style.image} ${mediaClassNames}`}
                imgClassName=""
                title={imgHoverTitle}
                loading="eager"
              />
            )}
          </Media>
          <Media at="tablet">
            {(mediaClassNames) => (
              <GatsbyImage
                alt={imgAlt}
                image={imgDataTabletOnly}
                className={`${style.image} ${mediaClassNames}`}
                imgClassName=""
                title={imgHoverTitle}
                loading="eager"
              />
            )}
          </Media>
          <Media greaterThanOrEqual="desktop">
            {(mediaClassNames) => (
              <GatsbyImage
                alt={imgAlt}
                image={imgData}
                className={`${style.image} ${mediaClassNames}`}
                imgClassName=""
                title={imgHoverTitle}
                loading="eager"
              />
            )}
          </Media>
        </Link>
      )}
      <div className={style.postBody}>
        {!sustainability ? (
          <Link
            className={`clearLinkStyle d-block ${style.category}`}
            to={categoryLink}
          >
            {category}
          </Link>
        ) : (
          <p className={`clearLinkStyle d-block ${style.category}`}>
            {category}
          </p>
        )}
        <Media lessThan="desktop">
          <Link className="clearLinkStyle d-block" to={slug}>
            <div className={style.title}>{title}</div>
            <div className={style.lead}>{lead}</div>
          </Link>
        </Media>
        <Media greaterThanOrEqual="desktop">
          <div className={style.title}>{title}</div>
          <div className={style.lead}>{lead}</div>
          {sustainability && (
            <Link className="clearLinkStyle d-block" to={slug}>
              <div className={style.readMoreButton}>Read more</div>
            </Link>
          )}
        </Media>
        <div className={style.authorSection}>
          {!sustainability && authorImgData && authorImgData?.images && (
            <Link to={authorSlug} className="clearLinkStyle d-block">
              <GatsbyImage
                image={authorImgData}
                className={style.authorPic}
                alt={authorName}
                title={authorName}
                loading="eager"
                style={{ borderRadius: '50%' }}
              />
            </Link>
          )}
          <div>
            {!sustainability && (
              <Link
                to={authorSlug}
                className={`${style.authorName} clearLinkStyle d-block`}
              >
                {authorName}
              </Link>
            )}
            <div className={style.timeToRead}>
              <span>{datePostedGmtToString(datePostedGmt)}</span>
              {!Number.isNaN(timeToRead) && (
                <>
                  <span> · </span>
                  <span>{t('articles.timeToRead', { timeToRead })}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LargePost.defaultProps = {
  authorImgData: {},
  category: 'recommended',
  categoryLink: '/',
  imgAlt: 'post featured image',
  imgData: null,
  imgDataTabletOnly: null,
  imgHoverTitle: '',
  lead: '',
  tag: '',
  timeToRead: 3,
};

LargePost.propTypes = {
  /** Featured image of a post (gatsby-image data) for Mobile and Desktop. */
  imgData: PropTypes.object,
  /** Featured image of a post (gatsby-image data) for Tablet only. */
  imgDataTabletOnly: PropTypes.object,
  /** Featured image alternative text. */
  imgAlt: PropTypes.string,
  /** Image title to display a tooltip on hover. */
  imgHoverTitle: PropTypes.string,
  /** Author's avatar image (gatsby-image data). */
  authorImgData: PropTypes.object,
  /** Author's name (first name + last name). */
  authorName: PropTypes.string.isRequired,
  /** Author's slug - the link to their page. */
  authorSlug: PropTypes.string.isRequired,
  /** Title of a post. */
  title: PropTypes.string.isRequired,
  /** Category of a post. */
  category: PropTypes.string,
  /** A link to a category page. */
  categoryLink: PropTypes.string,
  /** Lead - short post description. */
  lead: PropTypes.string,
  /** Approximate time to read (minutes). */
  timeToRead: PropTypes.number,
  /** Date when post has been published GMT (later converted to local time). */
  datePostedGmt: PropTypes.string.isRequired,
  /** Slug of the post to form a link to it */
  slug: PropTypes.string.isRequired,
  /** Post's Tag (like Featured) */
  tag: PropTypes.string,
};

export default LargePost;
