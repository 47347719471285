// extracted by mini-css-extract-plugin
export var authorName = "LargePost-module--authorName--e22a3";
export var authorPic = "LargePost-module--authorPic--26f62";
export var authorSection = "LargePost-module--authorSection--185fa";
export var category = "LargePost-module--category--2f4d1";
export var image = "LargePost-module--image--a4df2";
export var imageLink = "LargePost-module--imageLink--3d095";
export var largePost = "LargePost-module--largePost--69de6";
export var lead = "LargePost-module--lead--68721";
export var postBody = "LargePost-module--postBody--8001c";
export var readMoreButton = "LargePost-module--readMoreButton--115bb";
export var sustainability = "LargePost-module--sustainability--4849d";
export var tag = "LargePost-module--tag--8d214";
export var timeToRead = "LargePost-module--timeToRead--c38ce";
export var title = "LargePost-module--title--a2e69";